.com_info[data-v-f7e80fec] {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 5px;
}
.com_info .top_info[data-v-f7e80fec] {
  width: 100%;
  margin-bottom: 10px;
}
.com_info .top_info .top_item[data-v-f7e80fec] {
  display: inline-block;
}
.com_info .top_info .top_item p[data-v-f7e80fec] {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  line-height: 24px;
  margin-right: 20px;
}
.com_info .top_info .top_item p span[data-v-f7e80fec] {
  font-weight: normal;
}
.com_info .bottom_info[data-v-f7e80fec] {
  width: 100%;
  margin: 0 auto;
  font-size: 14px;
}
.com_info .bottom_info .info_list p[data-v-f7e80fec] {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  line-height: 24px;
  font-size: 14px;
}
.com_info .bottom_info .info_list span[data-v-f7e80fec] {
  margin-right: 14px;
  font-size: 14px;
}
.com_info .bottom_info .info_list .wxts[data-v-f7e80fec] {
  color: red;
  font-size: 14px;
  margin-top: 15px;
  font-size: 14px;
}
.top_btns .left_search[data-v-f7e80fec] {
  float: left;
  width: 30% !important;
}
.addBtn[data-v-f7e80fec] {
  position: relative;
  top: -15px;
}
.top_right_btns i[data-v-f7e80fec] {
  margin: 0 5px;
  cursor: pointer;
  font-size: 18px;
  line-height: 24px;
}
.top_right_btns .iconfont icon-bianji[data-v-f7e80fec] {
  color: #f15a24;
}
.top_right_btns .iconfont icon-shanchu[data-v-f7e80fec] {
  color: #f56c6c;
}
.saveAll[data-v-f7e80fec] {
  position: fixed;
  bottom: 46px;
  left: 218px;
  opacity: 0.8;
  z-index: 2004;
}
table[data-v-f7e80fec] {
  table-layout: fixed;
}
.content[data-v-f7e80fec] {
  margin: 0 auto;
}
.content span[data-v-f7e80fec] {
  line-height: 24px;
  font-size: 14px;
  color: #333;
}
.content tr[data-v-f7e80fec] {
  padding: 0;
  margin: 0;
  background: #fff;
}
.content input[data-v-f7e80fec] {
  width: 100%;
  line-height: 24px;
  padding: 0 6px;
  font-size: 14px;
  text-align: right;
  padding-right: 12px;
  color: #333;
}
.action_btns[data-v-f7e80fec] {
  text-align: center;
}
.action_btns i[data-v-f7e80fec] {
  font-size: 16px;
  cursor: pointer;
  line-height: 24px;
}
.action_btns .iconfont icon-bianji[data-v-f7e80fec] {
  color: #f15a24;
}
.action_btns .iconfont icon-shanchu[data-v-f7e80fec] {
  color: #f56c6c;
}